<template>
  <FocusTrap>
    <div class="card" id="mycard">

      <div class="card-header header-elements-sm-inline py-sm-0" >
        <h5 class="card-title py-sm-3"><span class="icon-list"></span>&nbsp;&nbsp;Subject's List</h5>
        <div class="header-elements">
          <form action="#">
            <div class="form-group-feedback form-group-feedback-right">
              <input  id="txtsearch" type="search" class="form-control wmin-sm-200" placeholder="Search..." autocomplete="off">
              <div class="form-control-feedback">
                <i class="icon-search4 font-size-base text-muted"></i>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="table-responsive">
        <table id="subject_table"
               class="table table-bordered table-columned"
               data-search="false"
               data-pagination="true"
               data-show-refresh="false"
               data-show-columns="false"
               data-page-list="[10, 25, 50, 100, ALL]"
               data-show-footer="false"
               data-toggle="context"
               data-target=".context-table">
          <thead>
          <tr>
            <th data-field="id" data-class="d-none">ID</th>
            <th data-formatter="runningFormatter" data-width="75">S.No</th>
            <th data-field="name" >Name</th>
          </tr>
          </thead>
          <tbody>
          </tbody>
          <tfoot>
          </tfoot>
        </table>
      </div>

      <!-- Context Menu -->
      <div id="context-menu" class="context-table">
        <div class="dropdown-menu">
          <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: darkgreen"></i>Refresh</a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item"> <i class="icon-pencil3" ></i>Modify</a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item"> <i class="icon-blocked text-danger" ></i>Remove</a>
        </div>
      </div>
      <!-- End of Context Menu -->

      <!-- Clickable menu -->
      <ul class="fab-menu fab-menu-fixed fab-menu-bottom-right is_stuck" data-fab-toggle="hover" z-index="0">
        <li>
          <a class="fab-menu-btn btn bg-primary-600 btn-float rounded-round btn-icon" @click="showModal">
            <i class="fab-icon-open icon-plus3"></i>
            <i class="fab-icon-close icon-plus3"></i>
          </a>
        </li>
      </ul>
      <!-- /clickable menu -->

      <vmodal name="subject_widow" transition="nice-modal-fade" :delay="100" :resizable="true" :width="500" :height="210" @before-open="beforeOpen" @before-close="beforeClose" >
        <SubjectForm v-bind:mysubject="subject" v-on:subject_saved="loadData" v-on:subject_window_closed="closeModal" ></SubjectForm>
      </vmodal>

    </div>
  </FocusTrap>
</template>

<script>
import SubjectForm from '@/views/lms/subject/SubjectForm.vue'
import { userService } from '@/store/auth-header.js'
import { store } from '@/store/store.js'

export default {
  name: 'SubjectView',
  components: {
    SubjectForm
  },
  store,
  data () {
    return {
      mytable: {},
      subject: JSON.parse('{"id":0,"name":"","parent_id":1}')
    }
  },
  created () {

  },
  mounted () {
    const self = this;

    this.$data.mytable = $('#subject_table');

    this.$data.mytable.bootstrapTable();
    $('[data-toggle="popover"]').popover();

    this.$data.mytable.contextmenu({
      target: '#context-menu',
      scopes: 'tbody > tr',
      onItem: function (row, e) {
        let id = $(row.children('*')[0]).text();

        if ($(e.target).text() === 'Modify') {
          self.modifyDocument(id);
        } else if ($(e.target).text() === 'Remove') {
          self.removeDocument(id);
        }
      }
    })

    $('#txtsearch').keyup(function () {
      let val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();
      $('#subject_table>tbody>tr').show().filter(function () {
        let text = $(this).text().replace(/\s+/g, ' ').toLowerCase();
        return !~text.indexOf(val);
      }).hide();
    })

    self.loadData();
  },
  methods: {
    closeModal () {
      this.$data.subject = JSON.parse('{"id":0,"name":"","parent_id":1}');
      this.$modal.hide('subject_widow');
    },
    showModal () {
      this.$modal.show('subject_widow');
    },
    beforeOpen () {
      console.log('beforeOpen')
    },
    beforeClose () {
      this.subject = JSON.parse('{"id":0,"name":"","parent_id":1}');
    },
    loadData () {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      this.$data.subject = JSON.parse('{"id":0,"name":"","parent_id":1}');

      self.$modal.hide('subject_widow');
      self.$data.mytable.bootstrapTable('load', []);

      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/subjects/after/1`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (_.isArray(resp.data)) {
            self.$data.mytable.bootstrapTable('load', resp.data);
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      }).finally(()=>{
        $('#mycard').unblock();
      });

    },
    modifyDocument (id) {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      self.$data.subject = JSON.parse('{"id":0,"name":"","parent_id":1}');


      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/subject/${id}`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          self.$data.subject = resp.data;
          self.$modal.show('subject_widow');
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      })
    },
    removeDocument (id) {
      const self = this;

      self.$data.subject.id = id;

      const requestOptions = {
        method: 'DELETE',
        mode: 'cors',
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.subject)
      }

      swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        timer: 3000,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          fetch(`${process.env.VUE_APP_ROOT_API}api/lms/subject/${id}`, requestOptions).then(userService.handleResponse).then(function (resp) {
            self.$modal.hide('subject_widow');
            self.loadData();
            swal(
              'Deleted!',
              'Your request has been processed',
              'success'
            )
          }).catch(function (err) {
            swal({ title: 'Oops', text: err.toString(), type: 'error' })
          })
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
