<template>
  <FocusTrap>
    <div class="card" id="mycard">

      <div class="card-header header-elements-inline">
        <legend class="font-weight-semibold text-uppercase font-size-sm">
          <i class="icon-file-text2"></i>
          Enter Subject Information
        </legend>

        <div class="header-elements">
          <div class="list-icons">
            <a class="list-icons-item" data-action="reload"></a>
            <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
          </div>
        </div>

      </div>

      <div class="card-body">

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Name:</label>
          <div class="col-md-10">
            <input type="text" id="txtname" class="form-control" placeholder="Subject Name" v-if="subject" v-model="subject.name" autocomplete="off">
          </div>
        </div>


        <div class="text-right">
          <button type="submit" class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple" @click="saveDocument">Save <i class="icon-paperplane ml-2"></i></button>
        </div>

      </div>
    </div>
  </FocusTrap>
</template>

<script>
import { userService } from '@/store/auth-header.js'
import { store } from '@/store/store.js'

export default {
  name: 'SubjectForm',
  data () {
    return {
      tanks:[],
      subject: JSON.parse('{"id":0,"name":"","parent_id":1}')
    }
  },
  store,
  component: {

  },
  props: {
    mysubject: {
      type: Object,
      required: true,
      default: JSON.parse('{"id":0,"name":"","parent_id":1}')
    }
  },
  beforeMount () {
    this.subject = this.mysubject; // save props data to itself's data and deal with it
  },
  created () {
    this.$data.subject = JSON.parse('{"id":0,"name":"","parent_id":1}')
  },
  mounted () {
    $('#txtname').focus();
  },
  methods: {
    closeWindow () {
      this.$emit('subject_window_closed');
    },
    saveDocument () {
      const self = this;

      console.log(JSON.stringify(self.$data.subject));

      if (self.$data.subject.name.trim().length == 0) {
        alert('Invalid Name');
        return;
      }

      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      const requestOptions = {
        method: (self.$data.subject.id == 0 ? 'POST' : 'PUT'),
        mode: 'cors',
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.subject)
      }

      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/subject`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          swal({ title: 'Success!', text: resp.msg, type: 'success' , onClose: () => { $('#txtname').focus()}, timer:1500 });
          self.subject = JSON.parse('{"id":0,"name":"","parent_id":1}');
          self.$emit('subject_saved',resp.data);
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' , onClose: () => { $('#txtname').focus()}, timer:3000 });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' , onClose: () => { $('#txtname').focus()}, timer:3000 });
      }).finally(()=>{
        $('#mycard').unblock();
      });

    }
  }
}
</script>

<style scoped>
  input:focus {
    background: #feff00;

  }

  textarea:focus {
    background: #feff00;

  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }
</style>
